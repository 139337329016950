import { headerHeight } from "../helper/constants";

class StickyATC extends HTMLElement {
  constructor() {
    super();
    this.scrollThreshold = 70; // Set scroll distance threshold
    this.atcStatic = document.querySelector('.js-static-atc');

    this.submitButton = this.querySelector('[type="submit"]');

    if (this.submitButton) this.submitButton.addEventListener('click', this.onClickHandler.bind(this));
  }

  connectedCallback() {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.classList.add('hidden');
          this.classList.remove('block');
        } else {
          this.classList.add('block');
          this.classList.remove('hidden');
        }
      });
    };
    const options = {
      root: null, // using the viewport as the root
      rootMargin: `-${headerHeight()}px 0px ${headerHeight()}px 0px`,
      threshold: 0 // callback will be triggered as soon as even one pixel is visible
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    observer.observe(this.atcStatic);
  }

  onClickHandler(evt) {
    if (this.submitButton.getAttribute('scroll') === 'true') {
      evt.preventDefault();

      const elementPosition = this.atcStatic.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - 500;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }
}

  customElements.define('sticky-atc', StickyATC);
